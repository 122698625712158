.video-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    height: 75vh;
    overflow-y: auto;
    padding: 0 5%;
    justify-content: flex-start;

    @media (max-width: 768px) {
        height: 75vh;
        padding-bottom: 5%;
    }

    .video-card {
        width: 32%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-height: 450px;
        box-sizing: border-box;

        @media (max-width: 1100px) {
            width: 46%;
            margin: 0 0 16px 0;
            flex-direction: column;
            max-height: 500px;
        }

        @media (max-width: 768px) {
            width: 100%;
            margin: 0 0 16px 0;
            flex-direction: column;
            max-height: 600px;
        }
    }

    .card-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 150px;
        position: relative;

        .title-box {
            position: sticky;
            top: 0;
            background: white;
            z-index: 1;
            margin-bottom: 8px;
            width: 100%;
        }

        .description-box {
            flex-grow: 1;
        }
    }

    .truncated-title {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 768px) {
            -webkit-line-clamp: unset;
            overflow: unset;
            text-overflow: unset;
        }
    }

    .truncated-description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 768px) {
            -webkit-line-clamp: unset;
            overflow: unset;
            text-overflow: unset;
        }
    }

    .status-container {
        display: flex;
        align-items: center;
        margin-top: 8px;
        min-height: 50px;
    }

    .date-container {
        display: flex;
        align-items: center;
        margin-bottom: 5%;
    }

    .date {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #8f8f8f;
    }

    .status {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .to-process {
        color: #d28219;
    }

    .processing {
        color: #1976d2;
    }

    .completed {
        color: green;
    }

    .failed {
        color: red;
    }

    .button-container {
        display: flex;
        justify-content: space-between;
    }

    .edit-button {
        color: gray;
    }

    .delete-button {
        color: red;
    }

    .popover {
        .popover-box {
            padding: 16px;
        }

        .popover-buttons {
            display: flex;
            justify-content: flex-end;
            padding: 8px;
        }

        .cancel-button {
            color: gray;

            &:hover {
                background-color: #cecece;
            }
        }

        .confirm-button {
            color: red;
            background-color: #e6e6e600;

            &:hover {
                background-color: #cecece;
            }
        }
    }
}