.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.MuiFormLabel-asterisk {
  display: none;
}

button.confirm-button {
  color: white;
  background-color: #003057;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #004074;
  }
}