.video-box {
    background-color: #FFF;
    top: 0;
    left: 0;
    right: 0;
    margin-top: 1%;
    margin-bottom: 1%;

    @media (max-width: 1200px) {
        margin-top: 5%;
    }

    @media (max-width: 768px) {
        margin-top: 7%;
        margin-bottom: 5%;
    }

    button.confirm-button {
        color: white;
        background-color: #003057;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover {
            background-color: #004074;
        }
    }
}