.navbar-box {
    padding-top: 5%;

    @media (max-width: 595px) {
        padding-top: 15%;
    }

    .MuiAppBar-root {
        background-color: #003057;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1100;
    }

    .yellow-underline {
        height: 4px;
        background-color: #ffd040;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .menu-button {
        color: white;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover {
            background-color: #004074;
        }
    }
}