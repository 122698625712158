.container {
    height: 80vh;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin-top: 3%;

    @media (max-width: 1200px) {
        margin-top: 5%;
    }

    @media (max-width: 768px) {
        margin-top: 7%;
    }

    .appbar {
        background-color: #e6e6e6;
        box-shadow: none;
    }

    .toolbar {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .search-box {
        display: flex;
        align-items: center;
        flex: 1;
        margin-right: 16px;

        @media (max-width: 768px) {
            margin-right: 0;
            width: 100%;
            margin-top: 20px;
        }
    }

    .search-input {
        background-color: white;
        border-radius: 4px;
        padding: 2px 4px;
        width: 100%;
    }
}