.main-container {
    // min-height: 90vh;

    // .video-section,
    // .form-section,
    // .comparison-section {
    //     min-height: 80vh;
    //     max-height: 80vh;
    // }

    @media (max-width: 1200px) {
        margin-top: 3%;
    }

    @media (max-width: 768px) {
        margin-top: 5%;
    }

    .video-section {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .form-section {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
        box-shadow: 3;
        border-radius: 8px;

        .MuiButton-root {
            margin-top: auto;
        }

        .keypoints-button {
            color: white;
            background-color: #d28219;
            transition: background-color 0.3s ease, color 0.3s ease;
            border-color: #d28219;
        }

        .keypoints-button:hover {
            background-color: #d6923a;
        }

        .confirm-button {
            color: white;
            background-color: #003057;
            transition: background-color 0.3s ease, color 0.3s ease;
        }

        .confirm-button:hover {
            background-color: #004074;
        }

        .confirm-button:disabled {
            color: #a9a9a9;
            border-color: #a9a9a9;
            cursor: not-allowed;
            opacity: 0.6;
        }
    }

    .comparison-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 16px;
        padding: 16px;
        box-shadow: 3;
        border-radius: 8px;

        .comparison-container {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 16px;
            height: 100%;
            min-height: 200px;
        }

        .compare-button {
            width: 100%;
            color: #003057;
            border-color: #003057;
        }

        .compare-button:disabled {
            color: #a9a9a9;
            border-color: #a9a9a9;
            cursor: not-allowed;
            opacity: 0.6;
        }
    }


    .info-container {
        display: block;
    }

    .date {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #8f8f8f;
        margin-bottom: 8px;
    }

    .status {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .to-process {
        color: #d28219;
    }

    .processing {
        color: #1976d2;
    }

    .completed {
        color: green;
    }

    .failed {
        color: red;
    }

    @media (max-width: 1000px) {
        .main-container {
            flex-direction: column;
        }

        .video-section,
        .form-section,
        .comparison-section {
            width: 95%;
            min-height: 90%;
        }
    }
}

.modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    box-shadow: 24px;
    padding: 16px;
    border-radius: 8px;

    @media (max-width: 768px) {
        width: 90%;
    }

    .modal-title {
        margin-bottom: 16px;
    }

    .modal-buttons {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
    }

    .cancel-button {
        color: gray;

        &:hover {
            background-color: #cecece;
        }
    }

    .confirm-button {
        color: red;
        background-color: #e6e6e600;

        &:hover {
            background-color: #cecece;
        }
    }
}